import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { StateService } from '../../service/state.service';

@Injectable({
  providedIn: 'root',
})
export class SelectService {
  controller = 'Select';
  private _httpClient = inject(HttpClient);
  private stateService = inject(StateService);
  getGrades(idSchool: number) {
    return this._httpClient.get<SelectItem<number>[]>(`${this.controller}/GetGrades?IdSchool=${idSchool}`);
  }
  getRolesForSelect() {
    return this._httpClient.get<SelectItemGroup[]>(`${this.controller}/GetRoles`);
  }
  getSelTopicsForSelect() {
    return this._httpClient.get<SelectItemGroup[]>(`${this.controller}/GetSelTopics`);
  }
  getSkillTopicsForSelect() {
    return this._httpClient.get<SelectItemGroup[]>(`${this.controller}/GetSkillTopics`);
  }
  getSelDiagnosticsForSelect(schoolId: number | undefined = undefined) {
    const idSchool = schoolId ? schoolId : this.stateService.schoolId;
    const idSchoolPeriod = this.stateService.schoolPeriodId;
    if (!idSchool || !idSchoolPeriod) {
      return;
    }
    return this._httpClient.get<SelectItem<number>[]>(
      `${this.controller}/GetSelDiagnostics?SchoolId=${idSchool}&SchoolPeriodId=${idSchoolPeriod}`,
    );
  }
  getSkillDiagnosticsForSelect() {
    const idSchool = this.stateService.schoolId;
    const idSchoolPeriod = this.stateService.schoolPeriodId;
    if (!idSchool || !idSchoolPeriod) {
      return;
    }
    return this._httpClient.get<SelectItem<number>[]>(
      `${this.controller}/GetSkillDiagnostics?SchoolId=${idSchool}&SchoolPeriodId=${idSchoolPeriod}`,
    );
  }
  getStylesDiagnosticsForSelect() {
    const idSchool = this.stateService.schoolId;
    const idSchoolPeriod = this.stateService.schoolPeriodId;
    if (!idSchool || !idSchoolPeriod) {
      return;
    }
    return this._httpClient.get<SelectItem<number>[]>(
      `${this.controller}/GetStylearnDiagnostics?SchoolId=${idSchool}&SchoolPeriodId=${idSchoolPeriod}`,
    );
  }
  getCompassDiagnosticsForSelect() {
    const idSchool = this.stateService.schoolId;
    const idSchoolPeriod = this.stateService.schoolPeriodId;
    if (!idSchool || !idSchoolPeriod) {
      return;
    }
    return this._httpClient.get<SelectItem<number>[]>(
      `${this.controller}/GetCompassDiagnostics?SchoolId=${idSchool}&SchoolPeriodId=${idSchoolPeriod}`,
    );
  }
  //GetCompassDiagnostics
  getSocialinkDiagnosticsForSelect(schoolId: number | undefined = undefined) {
    const idSchool = schoolId ? schoolId : this.stateService.schoolId;
    const idSchoolPeriod = this.stateService.schoolPeriodId;
    if (!idSchool || !idSchoolPeriod) {
      return;
    }
    return this._httpClient.get<SelectItem<number>[]>(
      `${this.controller}/GetSocialinkDiagnostics?SchoolId=${idSchool}&SchoolPeriodId=${idSchoolPeriod}`,
    );
  }
  getFamilinkDiagnosticsForSelect(schoolId: number | undefined = undefined) {
    const idSchool = schoolId ? schoolId : this.stateService.schoolId;
    const idSchoolPeriod = this.stateService.schoolPeriodId;
    if (!idSchool || !idSchoolPeriod) {
      return;
    }
    return this._httpClient.get<SelectItem<number>[]>(
      `${this.controller}/GetFamilinkDiagnostics?SchoolId=${idSchool}&SchoolPeriodId=${idSchoolPeriod}`,
    );
  }
  getGradesForSelect() {
    const schoolPeriodId = this.stateService.schoolPeriodId;
    return this._httpClient.get<SelectItem<number>[]>(
      `${this.controller}/GetGradesSelect?SchoolPeriodId=${schoolPeriodId}`,
    );
  }
  getGradesInstitutionForSelect() {
    const schoolPeriodId = this.stateService.schoolPeriodId;
    return this._httpClient.get<SelectItem<number>[]>(
      `${this.controller}/GetGradesInstitutionSelect?SchoolPeriodId=${schoolPeriodId}`,
    );
  }

  getgroupsByGradesId(GradesId: number[]) {
    return this._httpClient.post<SelectItemGroup[]>(`${this.controller}/GetGroupsByGradesId`, {
      GradesId,
    });
  }

  getSelDimensions() {
    return this._httpClient.get<SelectItemGroup[]>(`${this.controller}/GetSelDimensions`);
  }

  getAllGroups(isMultiSelect: boolean = false) {
    const schoolPeriodId = this.stateService.schoolPeriodId;
    if (!schoolPeriodId) {
      return;
    }
    return this._httpClient.get<SelectItem<number>[]>(
      `${this.controller}/GetAllGroups?SchoolPeriodId=${schoolPeriodId}&isMultiSelect=${isMultiSelect}`,
    );
  }
  getAllGroupsForMultiSelect() {
    const schoolPeriodId = this.stateService.schoolPeriodId;
    if (!schoolPeriodId) {
      return;
    }
    return this._httpClient.get<SelectItemGroup[]>(`${this.controller}/GetAllGroupsForMultiSelect`);
  }

  getMonthsForSelect() {
    return this._httpClient.get<SelectItem<number>[]>(`${this.controller}/GetMonthsForSelect`);
  }

  GetSelEducatorDiagnostics() {
    return this._httpClient.get<SelectItem<number>[]>(`${this.controller}/GetSelEducatorDiagnostics`);
  }

  GetSchoolsForSelDashboard() {
    return this._httpClient.get<SelectItem<number>[]>(`${this.controller}/GetSchoolsForSelDashboard`);
  }

  GetTimeLapses() {
    return this._httpClient.get<SelectItem<number>[]>(`${this.controller}/GetTimeLapses`);
  }

  getInstitutionsForSelect() {
    return this._httpClient.get<SelectItem<number>[]>(`${this.controller}/GetInstitutionsForSelect`);
  }

  getInstitutionForTopBar() {
    return this._httpClient.get<SelectItem<number>[]>(`${this.controller}/GetInstitutionsTopBar`);
  }

  getGroupsByInstitutionId(id: number) {
    return this._httpClient.get<SelectItem<number>[]>(`${this.controller}/GetGroupsByInstitutionId/${id}`);
  }

  getSelDiagnosticsInstitutionForSelect() {
    return this._httpClient.get<SelectItem<number>[]>(`${this.controller}/GetSelDiagnosticsInstitution`);
  }
  getAllGroupsGroupedByGrade() {
    return this._httpClient.get<SelectItemGroup[]>(`${this.controller}/GetGroupsGroupedByGrade`);
  }
}
